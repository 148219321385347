<script>
  export default {
    name: 'FormResponse',
    props: {
      status: { type: String, default: 'error' },
      message: { type: String, default: '' }
    }
  };
</script>

<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
    appear
  >
    <div class="flex items-center">
      <Icon
        v-if="status === 'error'"
        :icon="['far', 'times-circle']"
        class="text-red-600"
      />
      <Icon v-else :icon="['far', 'check-circle']" class="text-green-600" />

      <p
        class="ml-2 my-2"
        :class="status === 'error' ? 'text-red-600' : 'text-green-600'"
      >
        {{ message }}
      </p>
    </div>
  </transition>
</template>
