<template>
  <h2 class="my-8 text-center text-xl font-medium">Reset your password</h2>
  <div class="flex flex-col items-center">
    <PasswordResetForm :token="token" />
  </div>
</template>

<script>
  import PasswordResetForm from '@/components/forms/PasswordResetForm.vue';

  export default {
    name: 'ResetPassword',
    components: {
      PasswordResetForm
    },
    data() {
      return {
        token: ''
      };
    },
    mounted() {
      const { token } = this.$route.query;

      this.token = token;
    }
  };
</script>

<style scoped></style>
