<template>
  <form class="px-4 w-96 max-w-full">
    <p class="info-text">Enter your account email and a new password.</p>
    <div class="flex flex-col flex-grow">
      <FormResponse
        v-if="response.status"
        :status="response.status"
        :message="response.message"
      />

      <TextField label="email" name="email" v-model="fields.email" />

      <TextField
        label="new password"
        name="password"
        v-model="fields.password"
        type="password"
      />

      <TextField
        label="confirm password"
        name="confirmPassword"
        v-model="fields.confirmPassword"
        type="password"
      />
    </div>
    <TextBtn text="submit" @click="validate" :loading="loading" />
  </form>
</template>

<script>
  import api from '@/services/api.js';
  import { baseURL } from '@/services/api.js';
  import FormResponse from '@/components/notifications/FormResponse.vue';

  export default {
    name: 'PasswordResetForm',
    props: ['token'],
    components: {
      FormResponse
    },
    data() {
      return {
        loading: false,
        serverUrl: baseURL,
        fields: {
          email: '',
          password: '',
          confirmPassword: ''
        },
        fieldErrors: {
          email: '',
          password: '',
          confirmPassword: ''
        },
        response: {
          status: '',
          message: ''
        }
      };
    },
    methods: {
      validate() {
        let errors = {};
        // validate email
        // check that passwords match

        if (Object.keys(errors).length === 0) {
          this.submit();
        }
      },
      async submit() {
        this.response = { status: '', message: '' };
        this.loading = true;

        try {
          await api.post(`${window.location.origin}/passwordNew/${this.token}`, {
            ...this.fields
          });

          this.response = {
            status: 'success',
            message: 'Your password was successfully reset'
          };
        } catch (err) {
          // if (err.response) {
          //   console.log(err.response);
          // }

          this.response = {
            status: 'error',
            message: 'Your password could not be reset'
          };
        } finally {
          this.loading = false;
        }
      }
    }
  };
</script>

<style scoped></style>
